<template>
    <div v-if="dataSource && workers" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>
            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>
                        
                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Общая_информация")}}</div>

                                    <!--поле Год-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Год")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Year }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="years"
                                                :value="dataSource.Data.Object.Year"
                                                @input="update({ property: 'Data.Object.Year', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Тип документа-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Тип_документа")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.DocumentType ? $t(docTypes[dataSource.Data.Object.DocumentType]) : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="allowedDocTypes"
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="dataSource.Data.Object.DocumentType"
                                                @input="update({ property: 'Data.Object.DocumentType', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :rules="integerNotNullRule"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Name ? dataSource.Data.Object.Name : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Name"
                                                @input="update({ property: 'Data.Object.Name', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Параметры_номера")}}</div>
                                    
                                    <!--поле Индекс-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Индекс")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Index ? dataSource.Data.Object.Index : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Index"
                                                @input="update({ property: 'Data.Object.Index', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Счетчик-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Счетчик")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode || !dataSource.Data.Object.is_new_record" class="onlyReadData">
                                                {{ dataSource.Data.Object.Value ? dataSource.Data.Object.Value : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Value"
                                                @input="update({ property: 'Data.Object.Value', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                type="number"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Шаблон номера-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Шаблон_номера")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group">
                                                <div v-if="!isViewMode" @click="onTemplateSelect">
                                                    <v-icon small v-tooltip.left-center="$t('Редактировать_шаблон')">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData more-per-lab-wrap">
                                                    <template v-for="(item, index) in selectedTemplateItems">
                                                        <v-chip
                                                            :key="index"
                                                            class="person-label immutable-text"
                                                            small
                                                            label
                                                            v-if="item.includes('[') && item.includes(']')"
                                                        >
                                                            <div class="font-weight-bold">{{ removeSquareBracket(item) }}</div>
                                                        </v-chip>
                                                        <v-chip
                                                            :key="index"
                                                            class="person-label immutable-text"
                                                            small
                                                            label
                                                            v-else
                                                        >
                                                            <div>{{ item }}</div>
                                                        </v-chip>
                                                    </template>
                                                    

                                                    <!--<template v-else>
                                                        {{ $t("Не_указано") }}
                                                    </template>-->
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!--поле Резерв-->
                                    <v-row v-if="dataSource.Data.Object.DocumentType !== 2" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Резерв")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.Object.CounterReserve && dataSource.Data.Object.CounterReserve.Items ? dataSource.Data.Object.CounterReserve.Items.map(x => x.Value).join(", ") : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col v-if="dataSource.Data.Object.DocumentType !== 2" cols="12" sm="12" md="12" class="section-wrapper">
                            <!--Блок Доступ к журналу-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Доступ_к_журналу") }}</div>

                                    <!--поле Доступ-->
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Доступ") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div class="icon-click-row-group">
                                                <div v-if="!isViewMode" @click="onAccessSelect">
                                                    <v-icon small v-tooltip.left-center="selectAccessTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div class="onlyReadData more-per-lab-wrap">

                                                    <template v-if="dataSource.Data.Object.AccessList && dataSource.Data.Object.AccessList.length > 0">
                                                        <template v-for="workerWorkplaceId in dataSource.Data.Object.AccessList">
                                                            <v-workplace-chip
                                                                v-if="workerWorkplaceId !== '00000000-0000-0000-0000-000000000000'"
                                                                :key="workerWorkplaceId"
                                                                :id="workerWorkplaceId"
                                                                :main="isWorkerMissing(workerWorkplaceId)"
                                                                :name="getWorkerName(workerWorkplaceId)"
                                                                :canDelete="!isViewMode"
                                                                :delFunc="onAccessSelectedByEnterpriseId"
                                                            />
                                                        </template>
                                                    </template>
                                                    <template v-else-if="isViewMode">
                                                        {{ $t("Не_указано") }}
                                                    </template>

                                                    <template v-if="!isViewMode">
                                                        <v-select-members-chip
                                                            target-type="Member"
                                                            @on-select="onAccessSelectFromChip"
                                                            :items-params="accessSelectParams"
                                                        />
                                                    </template>
                                                    
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>

                <TemplateNumberDlg ref="TemplateNumberDlgRef" />

            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import TemplateNumberDlg from '@/components/dialogs/TemplateNumberDlg';
import i18n from '@/i18n'
import _ from 'lodash'

export default {
    name: "Counter",
    components: {
        Toolbar,
        TemplateNumberDlg
    },
    data() {
        return {
            tab: null,
            workers: null,
            templateItems: [
                '[СЧЕТЧИК]',
                '[ИНДЕКС ЖУРНАЛА]',
                '[ГОД - 2 ЦИФРЫ]',
                '[ГОД - 4 ЦИФРЫ]',
                '[НОМЕНКЛАТУРА]',
                '[ВИД ДОКУМЕНТА]',
                '[ТИП ДОКУМЕНТА]',
                '[ВЕРСИЯ]'
            ]
        }
    },
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        ...mapGetters('references', { docTypes: 'GetDocumentTypes'}),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerNotNullRule: 'getIntegerNotNullRule'
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0102021";
        },
        years() {
            return Array.from({ length: this.$moment().year() - 1999 }, (_, i) => i + 2000).reverse();
        },
        allowedDocTypes() {
            let $docTypes = this.docTypes;
            let arr = [1, 2, 3, 4, 5, 17, 18, 19, 20];
            if (process.env.VUE_APP_CONFIGURATION == 'C5')
                arr.push(14);
            return Object.keys($docTypes).reduce(function (types, docType) {
                let intDocType = parseInt(docType);
                if (arr.includes(intDocType)) {
                    types.push({ id: intDocType, Value: i18n.t($docTypes[intDocType]) });
                }
                return types;
            }, []);
        },
        selectAccessTooltip() {
            return i18n.t("Выберите_сотрудников");
        },
        selectedTemplateItems(){
            return this.convertTemplateToItemsArray(this.dataSource.Data.Object.Template);
        },
        accessSelectParams: {
            get: function() {
                return { 
                    title: "Выбор_сотрудников",
                    multiple: true,
                    includeInner: true,
                    selected: this.dataSource.Data.Object.AccessList ?? []
                }
            }
        }
    },
    methods: {
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        ...mapActions('references', { getWorkplaces: 'getWorkplaces' }),
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form.validate();
                    if(validate_result){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        async onTemplateSelect() {
            try
            {
                let template = await this.$refs.TemplateNumberDlgRef.open(this.dataSource.Data.Object.Template, this.templateItems);
                this.updateSource({ property: 'Data.Object.Template', value: template });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onAccessSelect() {
            try
            {
                let recipients = await this.$store.dispatch('dialogs/selectMembersNew/open', this.accessSelectParams);
                this.updateSource({ property: 'Data.Object.AccessList', value: recipients.map(x => x.workplaceId) });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onAccessSelectFromChip(e){
            let value = [ ...this.dataSource.Data.Object.AccessList, e.value.workplaceId];
            this.updateSource({ property: 'Data.Object.AccessList', value });
        },
        async onAccessSelectedByEnterpriseId(id){
            let tmp = Array.from(this.dataSource.Data.Object.AccessList);
            let itemToRemove = tmp.includes(id);
            if (itemToRemove) {
                let index = tmp.indexOf(id);
                if (index > -1) {
                    tmp.splice(index, 1);
                    this.updateSource({ property: 'Data.Object.AccessList', value: tmp });
                }
            }
        },
        convertTemplateToItemsArray(str) {
            var target = "[";
            var target2 = "]";
            var obj = [];
            var pos = -1;
            
            while ((pos = str.indexOf(target)) != -1) {
                if (pos == 0) {
                    let start = str.indexOf(target, 0);
                    let end = str.indexOf(target2, 0);
                    obj.push(str.slice(start, end + 1));
                    str = str.substr(end + 1);
                    pos = -1;
                }
                else {
                    let start = str.indexOf(target, 0);
                    let end = str.indexOf(target2, 0);
                    obj.push(str.slice(0, start));
                    obj.push(str.slice(start, end + 1));
                    str = str.substr(end + 1);
                }
            }

            return obj;
        },
        removeSquareBracket(text) {
            return text.replaceAll('[', '').replaceAll(']', '');
        },
        getWorkerName(wpId) {
            let worker = this.workers.find(x => x[0] === wpId);
            return worker?.[1] ?? wpId;
        },
        isWorkerMissing(wpId) {
            let worker = this.workers.find(x => x[0] === wpId);
            return worker === undefined;
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
    },
    async created() {
        this.workers = (await this.getWorkplaces()).InnerExecuters.Executers;
        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    },
    updated() {
        if(this.dataSource !== null){
            this.$refs?.form?.validate();
        }  
    }
}
</script>